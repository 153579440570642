.page404 {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url(../../public/bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page404Conent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: var(--white);
}

.page404BoxOne {
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  color: var(--navy-blue);
}

.page404BoxTwo {
  font-size: 32px;
  font-style: normal;
  color: var(--navy-blue);
  margin: 24px 0 0 0;
}

.page404BoxThree {
  font-size: 20px;
  font-style: normal;
  color: #434343;
  width: 290px;
  margin: 12px 0 0 0;
}

.page404Btn {
  display: flex;
  height: 43px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 42px 0 0 0;
  border-radius: 4px;
  background: var(--orange);
  color: var(--white);
  font-family: var(--fonts-default);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: all 0.5s ease;
}

.page404Btn:hover {
  background: var(--light-orange);
}

.page404Btn:active {
  background: var(--orange);
}

:has(.page404) .footer,
:has(.page404) .navbar {
  display: none;
}
